<template>
  <div>
    <div class="row" style="max-height: 400px; overflow:auto;">
      <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
      </loading>
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th width="12%">Data</th>
                <th width="10%">Tipo</th>
                <th width="20%">Ingresso</th>
                <th width="5%">Cupom</th>
                <th witdh="5%">Parcelas</th>
                <th witdh="5%">Descontos</th>
                <th witdh="10%">Valor Ingresso</th>
                <th witdh="10%">Valor Pago</th>
                <th witdh="10%">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payment in payments" :key="payment.id" class="font-size-11 mouse link" @click="showTransaction(payment.participant_id, payment.id, payment.status)">
                <td>{{ payment.created_at ? $moment(payment.created_at).format('DD/MM/YYYY HH:mm') : '' }}</td>
                <td>{{ payment.type_payment_label }}</td>
                <td>{{ payment.ticket ? payment.ticket.data.name : ''}}</td>
                <td>{{ payment.cupom ? payment.cupom.data.cupom : ''}}</td>
                <td>{{ payment.qty_quota }}</td>
                <td>{{ payment.discount + '%' }}</td>
                <td>{{ payment.ticket_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
                <td>{{ payment.status ? payment.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '' }}</td>
                <td>{{ statusLabel[payment.status] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-center"
      ref="modal-transactions"
      title="Transação"
      title-class="font-18"
      size="md"
    >
      <div class="row mt-2" v-if="transaction.order && transaction.order.type_payment === 3">
        <div class="table-responsive mt-0">
          <table class="table table-nowrap mb-0" style="border-top:none !important; line-height: 0.5px">
            <tbody>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Tipo:</th>
                <td style="border-top:none !important">{{ transaction.order ? typePayments[transaction.order.type_payment] : ''}}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Referência:</th>
                <td style="border-top:none !important">{{ transaction.reference }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Identificação:</th>
                <td style="border-top:none !important">{{ transaction.reconciliation_id }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Transação Doc:</th>
                <td style="border-top:none !important">{{ transaction.transaction_id }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Valor da transação:</th>
                <td style="border-top:none !important">{{ transaction.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Data e hora:</th>
                <td style="border-top:none !important">{{ $moment(transaction.datehour).format('DD/MM/YYYY HH:mm') }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Status:</th>
                <td style="border-top:none !important">{{ statusPays[transaction.status] }}</td>
              </tr>
              <tr style="background: #efefef">
                <th scope="row" width="60px" style="">Pagador:</th>
                <td style="">{{ transaction.payer_name }}</td>
              </tr>
              <tr style="background: #efefef">
                <th scope="row" width="60px" style="border-top:none !important">Documento:</th>
                <td style="border-top:none !important">{{ transaction.payer_doc }}</td>
              </tr>
              <tr style="background: #dde9e2">
                <th scope="row" width="60px" style="">Beneficiário Chave:</th>
                <td style="">{{ transaction.payee_key }}</td>
              </tr>
              <tr style="background: #dde9e2">
                <th scope="row" width="60px" style="border-top:none !important">Documento:</th>
                <td style="border-top:none !important">{{ transaction.payee_document }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-2" v-if="transaction.order && transaction.order.type_payment === 2">
        <div class="table-responsive mt-0">
          <table class="table table-nowrap mb-0" style="border-top:none !important; line-height: 0.5px">
            <tbody>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Tipo:</th>
                <td style="border-top:none !important">{{ transaction.order ? typePayments[transaction.order.type_payment] : ''}}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Ordem Compra:</th>
                <td style="border-top:none !important">{{ transaction.MerchantOrderId }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Identificação:</th>
                <td style="border-top:none !important">{{ transaction.PaymentId }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Transação ID:</th>
                <td style="border-top:none !important">{{ transaction.Tid }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Valor da transação:</th>
                <td style="border-top:none !important">{{ transaction.order.total_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Data e hora:</th>
                <td style="border-top:none !important">{{ $moment(transaction.created_at).format('DD/MM/YYYY HH:mm') }}</td>
              </tr>
              <tr>
                <th scope="row" width="60px" style="border-top:none !important">Status:</th>
                <td style="border-top:none !important">{{ transaction.returnMessage + ` (${transaction.returnCode})` }}</td>
              </tr>
              <tr style="background: #dde9e2">
                <th scope="row" width="60px" style="">Bandeira:</th>
                <td style="">{{ transaction.brand }}</td>
              </tr>
              <tr style="background: #dde9e2">
                <th scope="row" width="60px" style="border-top:none !important">Final C. Crédito:</th>
                <td style="border-top:none !important">{{ transaction.cNumber }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeModal()">Fechar</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Loading
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      payments: [],
      typePayments: ['Gratuito', 'Carteira MAG', 'Cartão de Crédito', 'PIX'],
      statusPays: ['Aberto', 'Pago', 'Falha de pagamento', 'Falha de pagamento'],
      transaction: {
        reference: null,
        transaction_id: null,
        total_amount: 0.00,
        created_at: null,
        status: null
      },
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger'],
      statusLabel: ['Aberto', 'Pago', 'Cancelado', 'Error']
    }
  },
  beforeMount () {
    this.GetPayments(this.idParticipant).then((res) => {
      this.payments = res
      this.loading = false
    }).catch(error => {
      console.log(error)
      this.loading = false
    })
  },
  computed: {
    ...mapState('participant', { logTransaction: 'logtransaction' })
  },
  methods: {
    ...mapActions('participant', {
      GetPayments: 'getAllPayments',
      GetTransaction: 'showTransaction'
    }),
    async showTransaction (id, orderId, status) {
      if (status === 1) {
        let loader = this.$loading.show()
        await this.GetTransaction({ id, orderId }).then((res) => {
          this.$refs['modal-transactions'].show()
          this.transaction = this.logTransaction
          loader.hide()
        })
      }
      return null
    },
    closeModal () {
      this.$refs['modal-transactions'].hide()
      this.transaction = {
        reference: null,
        transaction_id: null,
        total_amount: 0.00,
        created_at: null,
        status: null
      }
    },
    updateList () {
      this.GetPayments(this.idParticipant).then((res) => {
        this.payments = res
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }
  }
}
</script>
