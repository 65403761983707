<template>
  <div>
    <header data-v-58d612c2="" role="tab" class="card-header">
      <h6 data-v-58d612c2="" class="m-0">
        E-mails
      </h6>
    </header>
    <div class="row" style="max-height: 400px; overflow:auto;">
      <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
      </loading>
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead style="border: none !important">
              <tr>
                <th width="40%" style="border-top: none !important">Tipo</th>
                <th width="12%" style="border-top: none !important">Entrega</th>
                <th width="12%" style="border-top: none !important">Leitura</th>
                <th width="12%" style="border-top: none !important">Falha</th>
                <th witdh="10%" style="border-top: none !important">Abriu</th>
                <th witdh="10%" style="border-top: none !important">Clicou</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mail in mails" :key="mail.id">
                <td>{{ mail.typeEmail }}</td>
                <td class="font-size-11">
                  {{ mail.delivered_at ? $moment(mail.delivered_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ mail.opened_at ? $moment(mail.opened_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ mail.failed_at ? $moment(mail.failed_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td>{{ mail.opened }}</td>
                <td>{{ mail.clicked }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <header data-v-58d612c2="" role="tab" class="card-header">
      <h6 data-v-58d612c2="" class="m-0">
        Mensagens Whatsapp
      </h6>
    </header>
    <div class="row" style="max-height: 400px; overflow:auto;">
      <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
      </loading>
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th width="40%" style="border-top: none !important">Tipo</th>
                <th width="12%" style="border-top: none !important">Envio</th>
                <th width="12%" style="border-top: none !important">Entrega</th>
                <th width="12%" style="border-top: none !important">Leitura</th>
                <th width="12%" style="border-top: none !important">Falha</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="wa in waMessages" :key="wa.id">
                <td>{{ wa.title }}</td>
                <td class="font-size-11">
                  {{ wa.sent_at ? $moment(wa.sent_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ wa.delivered_at ? $moment(wa.delivered_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ wa.read_at ? $moment(wa.read_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
                <td class="font-size-11">
                  {{ wa.failed_at ? $moment(wa.failed_at).format('DD/MM/YYYY HH:mm') : '' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'

export default {
  components: {
    Loading
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      mails: [],
      waMessages: [],
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger']
    }
  },
  mounted () {
    this.getMessages()
  },
  beforeMount () {
    this.GetMails(this.idParticipant).then((res) => {
      this.mails = res
      this.loading = false
    }).catch(error => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    ...mapActions('participant', {
      GetMails: 'getAllMails',
      GetMessages: 'getAllWAMessages'
    }),
    getMessages () {
      this.GetMessages(this.idParticipant).then((res) => {
        this.waMessages = res
        console.log(this.waMessages)
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>
<style scoped>
.menu-notifications {
  display: flex;
  gap: 1rem;
}
</style>
