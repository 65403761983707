<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="xl"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <template #modal-header="{ close }">
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-2 text-center ml-0" v-if="participant.confirmation_code">
            <a :href="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/magcruise/qrcodes/${participant.confirmation_code}.png`" target="_blank" class="link">
              <img style="width:70px;" :src="`https://xcode-eventfy.sfo3.digitaloceanspaces.com/magcruise/qrcodes/${participant.confirmation_code}.png`"/>
            </a>
            <br />
            <span class="font-size-11">{{ participant.confirmation_code }}</span>
          </div>
          <div class="col-md-10" style="margin-left:-5px">
            <h5 class="font-size-15 text-truncate">{{ participant.full_name || `${participant.name} ${participant.last_name}` }}</h5>
            <span v-if="participant.ref" class="badge badge-pill font-size-13 badge-soft-primary">
              <i class="bx bxs-contact"></i> {{ participant.ref.data.name }}
            </span>
            <p class="text-muted mb-0">Inscrito em {{ $moment(participant.created_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
             <div class="flex items-baseline gap-1">
              <b-dropdown
                :disabled="participant.ref_id ? true : false"
                size="sm"
                variant="secondary"
                class="m-0 rounded-drop"
              >
                <template v-slot:button-content >
                  {{ participant.group ? participant.group.data.name : 'SEM GRUPO' }}
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                <span v-if="isUserAdmin || isUserMaster">
                  <b-dropdown-item-button
                    v-for="(t,i) in groups"
                    :key="i"
                    @click="toggleGroup(participant.id, t.id, t.name, participant.group ? participant.group.data.name : 'SEM GRUPO')"
                  >
                    {{ t.name }}
                  </b-dropdown-item-button>
                </span>
              </b-dropdown>
              <div v-if="participant.phone">
                <a :href="`http://wa.me/${formatPhone(participant.phone)}`" target="_blank" title="Abrir conversa WhatsApp">
                  <img src="@/assets/images/whatsapp.png" height="24px"/>
                </a>
              </div>
             </div>
          </div>
        </div>
      </div>

      <b-dropdown
        size="sm"
        :variant="statusClass[participant.status]"
        class="m-0 rounded-drop">
        <template v-slot:button-content >
          {{ statusList[participant.status] }}
          <i class="mdi mdi-chevron-down"></i>
        </template>
        <span v-if="isUserAdmin || isUserMaster">
          <b-dropdown-item-button
            v-for="(s,idx) in statusList"
            :key="idx"
            @click="toggleStatus(participant.id, idx)"
          >
            {{ s }}
          </b-dropdown-item-button>
        </span>
      </b-dropdown>
    </template>
    <div class="row">
      <div class="col-lg-12">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" lazy>
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Detalhes</span>
            </template>

            <div role="tablist">
              <b-card no-body class="mb-1 shadow-none">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-1
                      class="text-dark"
                      href="javascript: void(0);"
                    >Dados Cadastrais</a>
                    <b-badge
                      v-show="$v.participant.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <form autocomplete="off">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="full_name">Nome completo*</label>
                            <input
                              id="full_name"
                              v-model="participant.full_name"
                              name="full_name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.full_name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.full_name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="name">Nome e sobrenome*</label>
                            <input
                              id="name"
                              v-model="participant.name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="last_name">Ultimo Nome*</label>
                            <input
                              id="last_name"
                              v-model="participant.last_name"
                              name="last_name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.last_name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.last_name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="name">Nome p/ crachá*</label>
                            <input
                              id="name"
                              v-model="participant.badge_name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.badge_name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.badge_name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="phone">Telefone*</label>
                            <input
                              id="phone"
                              v-model="participant.phone"
                              name="phone"
                              type="phone"
                              v-mask="'X##(##) #####-####'"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.phone.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.phone.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div :class="{'col-sm-6': (participant.type === 5), 'col-sm-4': participant.type !== 5}">
                          <div class="form-group">
                            <label for="email">E-mail*</label>
                            <input
                              id="email"
                              v-model="participant.email"
                              name="email"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.email.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.email.required"
                              class="invalid-feedback"
                            >
                              O campo email é obrigatório.
                            </div>
                            <div
                              v-if="submitted && !$v.participant.email.email"
                              class="invalid-feedback"
                            >
                              Informe um e-mail válido.
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-sm-2"
                          v-if="participant.type !== 5 || (participant.ref && participant.ref.data.type !== 5)">
                          <div class="form-group">
                            <label for="cpf">CPF*</label>
                            <input
                              id="cpf"
                              v-model="participant.cpf"
                              v-mask="'###.###.###-##'"
                              @change="validCPF()"
                              name="cpf"
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': (submitted && $v.participant.cpf.$error) || errorCPF }"
                            />
                            <div
                              v-if="(submitted && $v.participant.cpf.$error) || errorCPF"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.participant.cpf.required">Campo obrigatório.</span>
                              <span v-if="errorCPF">CPF inválido.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="gender">Sexo*</label>
                            <select
                              id="gender"
                              v-model="participant.gender"
                              name="gender"
                              class="form-control"
                              :class="{ 'is-invalid': (submitted && $v.participant.gender.$error) }"
                            >
                              <option></option>
                              <option value="Masculino" v-if="participant.type !== 5">Masculino</option>
                              <option value="Feminino" v-if="participant.type !== 5">Feminino</option>
                              <option value="Man" v-if="participant.type === 5">Man</option>
                              <option value="Woman" v-if="participant.type === 5">Woman</option>
                            </select>
                            <div
                              v-if="(submitted && $v.participant.gender.$error)"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.participant.gender.required">Campo obrigatório.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div :class="{'col-sm-2': (participant.type === 4), 'col-sm-3': participant.type !== 4}">
                          <div class="form-group">
                            <label for="nationality">Nacionalidade*</label>
                            <input
                              id="nationality"
                              v-model="participant.nationality"
                              name="nationality"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.nationality.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.nationality.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="birth_date">Data Nascimento*</label>
                            <date-picker
                              v-model="participant.birth_date"
                              :first-day-of-week="1"
                              lang="pt-br"
                              format="DD/MM/YYYY"
                               :class="{
                                'is-invalid': submitted && $v.participant.birth_date.$error
                              }"
                            >
                            </date-picker>
                            <!-- <input
                              id="birth_date"
                              v-model="participant.birth_date"
                              name="birth_date"
                              v-mask="'##/##/####'"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.birth_date.$error
                              }"
                            /> -->
                            <div
                              v-if="submitted && !$v.participant.birth_date.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div :class="{'col-sm-2': (participant.type === 4), 'col-sm-3': participant.type !== 4}" v-if="participant.type !== 5">
                          <div class="form-group">
                            <label for="identification_doc">Nº Identidade*</label>
                            <input
                              id="identification_doc"
                              v-model="participant.identification_doc"
                              name="identification_doc"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.identification_doc.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.identification_doc.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="doc_emission">Data Emissão*</label>
                            <date-picker
                              v-model="participant.doc_emission"
                              :first-day-of-week="1"
                              lang="pt-br"
                              format="DD/MM/YYYY"
                              :class="{
                                'is-invalid': submitted && $v.participant.doc_emission.$error
                              }"
                            >
                            </date-picker>
                            <!-- <input
                              id="doc_emission"
                              v-model="participant.doc_emission"
                              name="doc_emission"
                              v-mask="'##/##/####'"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.doc_emission.$error
                              }"
                            /> -->
                            <div
                              v-if="submitted && !$v.participant.doc_emission.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="doc_validity">Data Validade*</label>
                            <date-picker
                              v-model="participant.doc_validity"
                              :first-day-of-week="1"
                              lang="pt-br"
                              format="DD/MM/YYYY"
                              :class="{
                                'is-invalid': submitted && $v.participant.doc_validity.$error
                              }"
                            >
                            </date-picker>
                            <!-- <input
                              id="doc_validity"
                              v-model="participant.doc_validity"
                              name="doc_validity"
                              v-mask="'##/##/####'"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.doc_validity.$error
                              }"
                            /> -->
                            <div
                              v-if="submitted && !$v.participant.doc_validity.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div
                          :class="{'col-sm-3': (participant.type === 5), 'col-sm-2': participant.type !== 5}"
                          v-if="participant.type === 4 || participant.type === 5 || (participant.ref && participant.ref.data.type === 5) || (participant.ref && participant.ref.data.type === 4)">
                          <div class="form-group">
                            <label for="passport_doc">Nº Passaporte*</label>
                            <input
                              id="passport_doc"
                              v-model="participant.passport_doc"
                              name="passport_doc"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.passport_doc.$error
                              }"
                            />
                             <div
                              v-if="submitted && !$v.participant.passport_doc.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="doc_country">País de emissão do documento*</label>
                            <input
                              id="doc_country"
                              v-model="participant.doc_country"
                              name="doc_country"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.doc_country.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.doc_country.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="birth_city">Cidade de Nascimento*</label>
                            <input
                              id="birth_city"
                              v-model="participant.birth_city"
                              name="birth_city"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.birth_city.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.birth_city.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="zip_code">CEP*</label>
                            <input
                              id="zip_code"
                              v-model="participant.zip_code"
                              name="zip_code"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.zip_code.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.zip_code.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="address">Endereço*</label>
                            <input
                              id="address"
                              v-model="participant.address"
                              name="address"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.address.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.address.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="city">Cidade*</label>
                            <input
                              id="city"
                              v-model="participant.city"
                              name="city"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.city.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.city.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="state" v-if="participant.type !== 5">UF*</label>
                            <label for="state" v-if="participant.type === 5">EStado*</label>
                            <select
                              v-if="participant.type !== 5"
                              class="form-control"
                              v-model="participant.state"
                              :class="{ 'is-invalid': submitted && $v.participant.state.$error }"
                            >
                              <option></option>
                              <option
                                v-for="(opt,idx) in states"
                                :key="idx"
                                :value="opt.value"
                              >
                                {{ opt.value || 'no label' }}
                              </option>
                            </select>
                            <input
                              v-if="participant.type === 5"
                              id="state"
                              v-model="participant.state"
                              name="state"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.state.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.state.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="country">País*</label>
                            <input
                              id="country"
                              v-model="participant.country"
                              name="country"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.country.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.country.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="address_complement">Complemento do endereço*</label>
                            <input
                              id="address_complement"
                              v-model="participant.address_complement"
                              name="address_complement"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.address_complement.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.address_complement.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-if="participant.type === 3">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="produtor_cod">Código de produtor*</label>
                            <input
                              id="produtor_cod"
                              v-model="participant.produtor_cod"
                              name="produtor_cod"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.produtor_cod.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.produtor_cod.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="corretora">Nome da corretora</label>
                            <input
                              id="corretora"
                              v-model="participant.corretora"
                              name="corretora"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="food_restriction">Restrição Alimentar ?</label>
                            <input
                              id="food_restriction"
                              type="text"
                              v-model="participant.food_restriction"
                              name="food_restriction"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="polo_tshirt">Tamanho de camisa polo</label>
                            <input
                              id="polo_tshirt"
                              v-model="participant.polo_tshirt"
                              name="polo_tshirt"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.polo_tshirt.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.polo_tshirt.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="shoe_size">Tamanho de calçado</label>
                            <input
                              id="shoe_size"
                              v-model="participant.shoe_size"
                              name="shoe_size"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.participant.shoe_size.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.participant.shoe_size.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="obs">Observação</label>
                            <textarea
                              id="obs"
                              rows="3"
                              v-model="participant.obs"
                              name="obs"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <Renderer
                          v-for="(element, name) in formCustom"
                          :key="name"
                          :element="element"
                          @status="saveStatus"
                          ref="dynamicForm"
                          v-model="participantCustom[name]"
                        />
                      </div> -->
                    </form>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1" v-if="displayAereo">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Transporte Áereo <span class="badge badge bg-dark float-end text-light">{{ participant.air_transport ? '1' : '' }}</span></a>
                    <b-badge
                      v-if="(participant.air_transport > 0) && $v.transport.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="form-group row mb-9">
                      <label for="session_register" class="col-form-label col-lg-3">Precisa de transporte aéreo ?</label>
                      <div class="col-lg-2">
                        <select
                          class="custom-select"
                          name="air_transport"
                          id="air_transport"
                          v-model="participant.air_transport"
                          :class="{
                            'is-invalid': submitted && $v.participant.air_transport.$error,
                          }">
                          <option value="1">SIM</option>
                          <option value="0">NÃO</option>
                        </select>
                        <div
                          v-if="submitted && !$v.participant.air_transport.required"
                          class="invalid-feedback"
                        >
                          O campo é obrigatório.
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="participant.air_transport > 0">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="boarding_date">Data de chegada ao Rio de Janeiro</label>
                          <date-picker
                            v-model="transport.boarding_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                            :class="{
                              'is-invalid': submitted && $v.transport.boarding_date.$error,
                            }"
                          >
                          </date-picker>
                          <div
                            v-if="submitted && !$v.transport.boarding_date.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="form-group">
                          <label for="departure_airport">Aeroporto de Embarque</label>
                          <input
                            id="departure_airport"
                            v-model="transport.departure_airport"
                            name="departure_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transport.departure_airport.$error,
                            }"
                          />
                           <div
                            v-if="submitted && !$v.transport.departure_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="return_date">Data de retorno para sua ciadde de origem</label>
                          <date-picker
                            v-model="transport.return_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                            :class="{
                              'is-invalid': submitted && $v.transport.return_date.$error,
                            }"
                          >
                          </date-picker>
                          <div
                            v-if="submitted && !$v.transport.return_date.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="form-group">
                          <label for="return_airport">Aeroporto de Retorno</label>
                          <input
                            id="return_airport"
                            v-model="transport.return_airport"
                            name="return_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transport.return_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transport.return_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1" v-if="!participant.ref_id && displayCompanion">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-3
                      class="text-dark"
                      href="javascript: void(0);"
                    >Acompanhante <span class="badge badge bg-dark float-end text-light">{{ participant.companion ? participant.companion.data.length : '0' }}</span></a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div
                      v-if="!participant.has_companion || participant.companion.data.length < 2"
                      role="alert"
                      class="alert alert-dismissible alert-info"
                    >
                      <i class="mdi mdi-alert-circle-outline me-2"></i> Deseja adicionar acompanhante ?
                      <a href="javascript: void(0);" @click="newCompanion(participant.id)">
                        [Clique aqui]
                      </a>
                    </div>
                    <div class="row" v-if="participant.has_companion">
                      <div class="col-md-6" v-for="(c, i) in companion" :key="i" @click="openCompanion(c.id)">
                        <div class="card mini-stats-wid border-gray link">
                          <div class="card-body">
                            <div class="media">
                              <div class="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                <span class="avatar-title">
                                  <i class="bx bx-group font-size-24"></i>
                                </span>
                              </div>
                              <div class="media-body ml-3">
                                <h5 class="font-size-14 mb-1">
                                  {{ c.full_name || `${c.name} ${c.last_name}` }}
                                  <span v-if="c.event_companion" class="badge badge-pill font-size-12 badge-soft-primary">Evento</span>
                                  <span v-if="c.travel_companion" class="badge badge-pill font-size-12 badge-soft-danger ml-1">Viagem</span>
                                </h5>
                                <p class="text-muted mb-0">{{ c.email }}</p>
                                <p class="text-muted mb-0">{{ c.phone }}</p>
                                <!-- <h4 class="">{{ companion.name }}</h4>
                                <p class="text-muted font-weight-medium">{{ companion.email }}</p>
                                <p class="text-muted font-weight-medium">{{ companion.phone }}</p> -->
                              </div>
                            </div>
                          </div>
                          <!-- end card-body -->
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-4
                      class="text-dark"
                      href="javascript: void(0);"
                    >Contato de Emergência</a>
                  </h6>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="name">Grau de parentesco</label>
                          <input
                            id="name"
                            v-model="contact.type"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contact.type.$error
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contact.type.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name">Nome completo</label>
                          <input
                            id="name"
                            v-model="contact.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contact.name.$error
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contact.name.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label for="phone">Telefone</label>
                          <input
                            id="phone"
                            v-model="contact.phone"
                            v-mask="'X##(##) #####-####'"
                            name="phone"
                            type="phone"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contact.phone.$error
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contact.phone.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>

          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">Anexos</span>
            </template>
            <attachments :attachments=attachments :idParticipant=idParticipant></attachments>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-envelope"></i>
              </span>
              <span class="d-none d-sm-inline-block">Histórico</span>
            </template>
            <historics :idParticipant=idParticipant></historics>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-cog"></i>
              </span>
              <span class="d-none d-sm-inline-block">Notificações</span>
            </template>
            <log-mails :idParticipant=idParticipant></log-mails>
          </b-tab>
          <b-tab v-if="participant.need_payment">
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-cog"></i>
              </span>
              <span class="d-none d-sm-inline-block">Pagamento</span>
            </template>
            <log-payments :idParticipant=idParticipant></log-payments>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <p class="text-muted mb-0" style="flex:auto">Última atualização em {{ $moment(participant.updated_at).format('D [de] MMMM YYYY [às] HH:mm') }}</p>
      <b-button v-if="isUserAdmin && participant.id" variant="danger" @click="deleteParticipant(participant)" class="float-left">Excluir Inscrito</b-button>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveParticipant()" v-if="isUserAdmin || isUserMaster">Salvar</button>
    </template>
    <companion-form v-model="modalForm" :idParticipant="idParticipant" :type="participant.type" ref="formCompanion" @created="loadItem(idParticipant)"></companion-form>
    <participant-form v-model="modalParticipant" ref="formParticipant"></participant-form>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { mapActions, mapState, mapGetters } from 'vuex'
import { states } from './statesData'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pt-br'
import Moment from 'moment'
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import ParticipantForm from './ModalForm'
import CompanionForm from './Companion'
import Attachments from './Attachments'
import Historics from './Historics'
import LogMails from './LogMails'
import LogPayments from './LogPayments'
import { cpf } from 'cpf-cnpj-validator'
// import Renderer from '@/components/formSchema/Renderer'

export default {
  name: 'form-participant',
  components: {
    Loading,
    // Renderer,
    DatePicker,
    CompanionForm,
    ParticipantForm,
    Attachments,
    Historics,
    LogMails,
    LogPayments
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Participante',
      loading: true,
      selected: '',
      errorCPF: false,
      status: null,
      fullPage: true,
      submitted: false,
      states: states,
      attachments: [],
      // participantCustom: {},
      participant: {
        customData: [],
        air_transport: 0,
        displayAereo: 0
      },
      formCustom: {},
      transport: {},
      contact: {},
      companion: {},
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger', 'info'],
      modal: false,
      modalForm: false,
      modalParticipant: false
    }
  },
  validations () {
    let transport = {}
    let fNormal = {
      name: { required },
      full_name: { required },
      last_name: { required },
      badge_name: { required },
      email: { required, email },
      phone: { required },
      gender: { required },
      nationality: { required },
      birth_date: { required },
      doc_emission: { required },
      doc_validity: { required },
      doc_country: { required },
      birth_city: { required },
      zip_code: { required },
      address: { required },
      city: { required },
      state: { required },
      country: { required },
      address_complement: { required },
      polo_tshirt: { required },
      shoe_size: { required },
      air_transport: { required }
    }
    if (this.participant.type !== 5) {
      fNormal = {
        ...fNormal,
        cpf: { required },
        identification_doc: { required }
      }
    }
    if (this.participant.type === 3) {
      fNormal = {
        ...fNormal,
        produtor_cod: { required }
      }
    }
    if (this.participant.type === 5 || this.participant.type === 4) {
      fNormal = {
        ...fNormal,
        passport_doc: { required }
      }
    }
    if (this.participant.air_transport && this.displayAereo) {
      transport = {
        ...transport,
        departure_airport: { required },
        return_airport: { required },
        return_date: { required },
        boarding_date: { required }
      }
    }
    return {
      participant: fNormal,
      contact: {
        type: { required },
        name: { required },
        phone: { required }
      },
      transport: transport }
  },
  methods: {
    ...mapActions('settingRSVP', {
      GetRSVP: 'GetRSVP'
    }),
    ...mapActions('group', {
      getAll: 'getAll'
    }),
    // ...mapActions('form', {
    //   getByGroup: 'showByGroup'
    // }),
    ...mapActions('participant', {
      getParticipant: 'getParticipant',
      postData: 'save',
      updateStatus: 'changeStatus',
      updateType: 'changeType',
      destroyParticipant: 'deleteParticipant'
    }),
    formatPhone (phone) {
      if (phone) {
        return phone.replace(/\D/g, '')
      }
    },
    validCPF () {
      const cpfValid = cpf.isValid(this.participant.cpf)
      if (!cpfValid) {
        this.errorCPF = true
        return
      }
      this.errorCPF = false
    },
    // isValid () {
    //   const normalRef = this.getNormalRef()
    //   return normalRef.reduce((last, ref) => ref?.isValid() && last, true)
    // },
    // getNormalRef () {
    //   return this.$refs.dynamicForm
    // },
    // saveStatus (status) {
    //   this.status = status
    // },
    // loadCustomForm () {
    //   if (this.participant.group_id) {
    //     this.getByGroup(this.participant.group_id)
    //       .then(() => {
    //         this.formCustom = parse(this.form.attributes.data)
    //       }).then(() => {
    //         console.log('...')
    //       })
    //   }
    // },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Participante'
        this.getParticipant(id).then((data) => {
          this.participant = this.data
          this.participant.birth_date = Moment(this.participant.birth_date, 'YYYY-MM-DD').toDate()
          this.participant.doc_emission = Moment(this.participant.doc_emission, 'YYYY-MM-DD').toDate()
          this.participant.doc_validity = Moment(this.participant.doc_validity, 'YYYY-MM-DD').toDate()
          if (this.data.transports) {
            this.transport = this.data.transports.data
            this.transport.boarding_date = Moment(this.transport.boarding_date, 'YYYY-MM-DD').toDate()
            this.transport.return_date = Moment(this.transport.return_date, 'YYYY-MM-DD').toDate()
          }
          if (this.data.contact) {
            this.contact = this.data.contact.data
          }
          if (this.data.companion) {
            this.companion = this.data.companion.data
          }
          if (this.data.attachments) {
            this.attachments = this.data.attachments.data
          }
          // if (this.data.formData) {
          //   /* eslint-disable camelcase */
          //   const retornoMap = {}
          //   this.data.formData.data.forEach(({ form_attribute_id, data }) => {
          //     retornoMap[form_attribute_id] = data
          //   })
          //   this.participantCustom = retornoMap
          //   console.log(this.participantCustom)
          // }
        }).then(() => {
          this.loading = false
          // this.loadCustomForm()
        })
      }
    },
    newCompanion (participantId) {
      this.$refs.formCompanion.open(participantId)
      this.modalForm = true
    },
    openCompanion (participantId) {
      this.$refs.formParticipant.open(participantId)
      this.modalParticipant = true
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    deleteParticipant (participant) {
      if (this.isUserAdmin) {
        Swal.fire({
          title: 'Você tem certeza ?',
          html: `Será excluído do sistema <b>${participant.name}</b>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#f46a6a',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'SIM, DESEJO EXCLUIR!'
        }).then(result => {
          if (result.value) {
            try {
              this.destroyParticipant(participant.id).then((res) => {
                successMessage('Sucesso: Participante excluído com sucesso.', 2500)
                this.close()
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              errorMessage('Alerta: ' + err.response.data.message)
            }
          }
        })
        return
      }
      errorMessage('Atenção: Você não tem permissão para realizar essa ação!', 2500)
    },
    toggleGroup (participantId, groupId, newGroup, oldGroup) {
      if (this.isUserAdmin || this.isUserMaster) {
        Swal.fire({
          title: 'Você tem certeza?',
          html: `Mudar grupo de <b>${oldGroup}</b> para <b>${newGroup}</b> <br /><small>Dados podem ser perdidos.</small>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#388741',
          cancelButtonColor: '#4a4848',
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'SIM, MUDAR GRUPO'
        }).then(result => {
          if (result.value) {
            try {
              this.updateType({ id: participantId, group_id: groupId }).then((res) => {
                successMessage('Sucesso: Grupo atualizado.', 2500)
              }).then(() => {
                this.loadItem(participantId)
              }).catch((err) => {
                errorMessage('Alerta: ' + err.message)
              })
            } catch (err) {
              console.log(err)
            }
          }
        })
        return
      }
      errorMessage('Atenção: Você não tem permissão para realizar essa ação!', 2500)
    },
    async toggleStatus (participantId, status) {
      if (this.isUserAdmin || this.isUserMaster) {
        if (status === 2) {
          Swal.fire({
            title: 'Você tem certeza?',
            input: 'select',
            html: `Mudar status de <b>${this.statusList[this.participant.status]}</b> para <b>${this.statusList[status]}</b>`,
            inputOptions: {
              1: 'Documentação',
              2: 'Contato telefônico',
              3: 'Emissão aéreo',
              4: 'Emissão transfer',
              5: 'Confirmação',
              6: 'Dados Aéreo'
            },
            icon: 'question',
            inputPlaceholder: 'Selecione motivo da pendência',
            showCancelButton: true,
            confirmButtonColor: '#388741',
            cancelButtonColor: '#4a4848',
            cancelButtonText: 'CANCELAR',
            confirmButtonText: 'SIM, MUDAR STATUS',
            inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value) {
                  resolve()
                } else {
                  resolve('Você precisa seleciona o motivo')
                }
              })
            }
          }).then(result => {
            if (result.value) {
              try {
                this.updateStatus({ id: participantId, status, refSatus: result.value }).then((res) => {
                  successMessage('Sucesso: Status atualizado.', 2500)
                }).then(() => {
                  this.loadItem(participantId)
                }).catch((err) => {
                  errorMessage('Alerta: ' + err.message)
                })
              } catch (err) {
                console.log(err)
              }
            }
          })
        } else {
          Swal.fire({
            title: 'Você tem certeza?',
            html: `Mudar status de <b>${this.statusList[this.participant.status]}</b> para <b>${this.statusList[status]}</b>`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#388741',
            cancelButtonColor: '#4a4848',
            cancelButtonText: 'CANCELAR',
            confirmButtonText: 'SIM, MUDAR STATUS'
          }).then(result => {
            if (result.value) {
              try {
                this.updateStatus({ id: participantId, status }).then((res) => {
                  successMessage('Sucesso: Status atualizado.', 2500)
                }).then(() => {
                  this.loadItem(participantId)
                }).catch((err) => {
                  errorMessage('Alerta: ' + err.message)
                })
              } catch (err) {
                console.log(err)
              }
            }
          })
        }
        return
      }
      errorMessage('Atenção: Você não tem permissão para realizar essa ação!', 2500)
    },
    getGroups () {
      let params = {
        search: null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    async saveParticipant () {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scroll(0, 0)
        return
      }
      // if (!this.isValid()) {
      //   return
      // }
      this.loading = true
      this.participant.transport = this.transport
      this.participant.contact = this.contact
      // this.participant.customData = this.participantCustom
      await this.postData(this.participant).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      if (this.modal) {
        this.participant = {}
        this.transport = {}
        this.contact = {}
        this.$v.$reset()
        this.$emit('created')
        this.modal = false
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    // ...mapState('form', { form: 'form' }),
    ...mapGetters('settings', { statusList: 'STATUSLIST' }),
    ...mapState('settingRSVP', { settingRSVP: 'settingRSVP' }),
    ...mapState('participant', { data: 'participant' }),
    ...mapState('group', ['groups']),
    displayAereo () {
      // if (this.participant.type !== 1) {
      //   return true
      // }
      return true
    },
    displayCompanion () {
      if (parseInt(this.settingRSVP.registration_type) === 2 && (this.participant.type === 2 || this.participant.type === 4 || this.participant.type === 5)) {
        return true
      }
      return false
    },
    isUserAdmin () {
      return this.$store.getters['auth/isAdmin']
    },
    isUserMaster () {
      return this.$store.getters['auth/isMaster']
    }
  },
  mounted () {
    this.getGroups()
    this.GetRSVP()
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'participant.state' (value) {
      this.selected = this.states.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.modal-header {
  background: #000 !important;
  color: #fff;
}
.border-gray {
  border: 1px solid #e9e9e9;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f3f3f3 !important;
  opacity: 1;
}
</style>
