<script>
/**
 * Stat component -- specify the widget icon, title and value.
 */
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  }
}
</script>

<template>
  <div class="card mini-stats-wid">
    <div class="card-body" style="padding: 0.50rem">
      <div class="media">
        <div class="media-body text-left pt-3">
          <h4 class="mb-2">{{data.value}}</h4>
          <p class="font-weight-medium mb-0 font-size-12" :class="'text-' + data.color">{{data.title}}</p>
        </div>

        <div v-if="data.title !== 'INSCRITOS'" class="badge align-self-top p-1 font-size-11" :class="'bg-'+ data.color">{{ data.perc }}</div>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
<style>
  .text-purple { color: #681AE8 !important; }
  .text-blue { color: #4D8FF2 !important; }
  .text-orange { color: #F26516 !important; }
  .text-green { color: #01B366 !important; }
  .text-red { color: #F51919 !important; }
  .bg-dark { background: #00000047 !important; color: #2b322e;}
  .bg-purple { background: #681ae847 !important; color: #2b322e;}
  .bg-blue { background: #4d8ff257 !important; color: #2b322e;}
  .bg-orange { background: #f265164f !important; color: #2b322e;}
  .bg-green { background: #01b36669 !important; color: #2b322e;}
  .bg-red { background: #f5191966 !important; color: #2b322e;}
</style>
