<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-12" v-if="isUserAdmin || isUserMaster">
        <div class="input-group bg-light rounded">
          <input
            type="text"
            placeholder="Escreva aqui informações para armazenar no histórico e depois clique em enviar"
            aria-describedby="button-addon2"
            class="form-control bg-transparent border-0"
            v-model="form.details"
          >
          <button type="button" id="button-addon2" class="btn btn-primary" @click="saveHistoric()">
            <i class="bx bxs-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row" style="max-height: 400px; overflow:auto;">
      <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
      </loading>
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th width="12%">Data</th>
                <th width="18%">Tipo</th>
                <th witdh="15%">Operador</th>
                <th witdh="45%">Detalhes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="historic in historics" :key="historic.id">
                  <td class="font-size-12">{{ $moment(historic.created_at).format('DD/MM/YYYY HH:mm') }}</td>
                  <td>{{ types[historic.type] }}</td>
                  <td>{{ historic.operator ? historic.operator.data.name : 'SISTEMA' }}</td>
                  <td>{{ historic.details }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      historics: [],
      submitted: false,
      showFormAttachment: false,
      form: {},
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger'],
      types: [
        '',
        'Alteração de Status',
        'Alteração de Categoria',
        'Adicionou Anexo',
        'Removeu Anexo',
        'Visualizou',
        'Ultima Edição',
        'Registro',
        'Inscrição recebida'
      ],
      modalForm: false
    }
  },
  validations: {
    form: {
      details: { required }
    }
  },
  beforeMount () {
    this.GetHistorics(this.idParticipant).then((res) => {
      this.historics = res.data
      this.loading = false
    }).catch(error => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    ...mapActions('participant', {
      GetHistorics: 'getAllHistoric',
      postHistoric: 'saveHistoric'
    }),
    async saveHistoric () {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      this.form.participant_id = this.idParticipant
      await this.postHistoric(this.form).then(res => {
        let message = 'Sucesso: Historico registrado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.clean()
        this.updateList()
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    clean () {
      this.form = {}
    },
    updateList () {
      this.GetHistorics(this.idParticipant).then((res) => {
        this.historics = res.data
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }
  },
  computed: {
    // ...mapState('auth', ['user']),
    isUserAdmin () {
      return this.$store.getters['auth/isAdmin']
    },
    isUserMaster () {
      return this.$store.getters['auth/isMaster']
    }
  }
}
</script>
<style scoped>
.dotted { border: 1px dotted #556ee6 !important}
</style>
