<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="xl"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">Detalhes</span>
            </template>

            <div role="tablist">
              <b-card no-body class="mb-1 shadow-none">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.dados-1
                      class="text-dark"
                      href="javascript: void(0);"
                    >Dados Cadastrais</a>
                    <b-badge
                      v-show="$v.companion.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="dados-1" visible accordion="companion-accordion" role="tabpanel">
                  <b-card-body>
                    <form autocomplete="off">
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="event_companion">Acomp. Evento</label>
                            <select
                              id="event_companion"
                              v-model="companion.event_companion"
                              name="event_companion"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.event_companion.$error
                              }"
                            >
                              <option></option>
                              <option value="1">SIM</option>
                              <option value="0">NÃO</option>
                            </select>
                            <div
                              v-if="submitted && !$v.companion.event_companion.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="travel_companion">Acomp. Viagem</label>
                            <select
                              id="travel_companion"
                              v-model="companion.travel_companion"
                              name="travel_companion"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.travel_companion.$error
                              }"
                            >
                              <option></option>
                              <option value="1">SIM</option>
                              <option value="0">NÃO</option>
                            </select>
                            <div
                              v-if="submitted && !$v.companion.travel_companion.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="full_name">Nome completo*</label>
                            <input
                              id="full_name"
                              v-model="companion.full_name"
                              name="full_name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.full_name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.full_name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="name">Nome e sobrenome*</label>
                            <input
                              id="name"
                              v-model="companion.name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.name.required"
                              class="invalid-feedback"
                            >
                              O campo nome é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="last_name">Ultimo Nome*</label>
                            <input
                              id="last_name"
                              v-model="companion.last_name"
                              name="last_name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.last_name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.last_name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="name">Nome p/ crachá*</label>
                            <input
                              id="name"
                              v-model="companion.badge_name"
                              name="name"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.badge_name.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.badge_name.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="phone">Telefone*</label>
                            <input
                              id="phone"
                              v-model="companion.phone"
                              name="phone"
                              type="phone"
                              v-mask="'X##(##) #####-####'"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.phone.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.phone.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div :class="{'col-sm-6': (type === 5), 'col-sm-4': (type !== 5)}">
                          <div class="form-group">
                            <label for="email">E-mail*</label>
                            <input
                              id="email"
                              v-model="companion.email"
                              name="email"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.email.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.email.required"
                              class="invalid-feedback"
                            >
                              O campo email é obrigatório.
                            </div>
                            <div
                              v-if="submitted && !$v.companion.email.email"
                              class="invalid-feedback"
                            >
                              Informe um e-mail válido.
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-sm-2" v-if="type !== 5">
                          <div class="form-group">
                            <label for="cpf">CPF*</label>
                            <input
                              id="cpf"
                              v-model="companion.cpf"
                              v-mask="'###.###.###-##'"
                              @change="validCPF()"
                              name="cpf"
                              type="text"
                              class="form-control"
                              :class="{ 'is-invalid': (submitted && $v.companion.cpf.$error) || errorCPF }"
                            />
                            <div
                              v-if="(submitted && $v.companion.cpf.$error) || errorCPF"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.companion.cpf.required">Campo obrigatório.</span>
                              <span v-if="errorCPF">CPF inválido.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="gender">Sexo*</label>
                            <select
                              id="gender"
                              v-model="companion.gender"
                              name="gender"
                              class="form-control"
                              :class="{ 'is-invalid': (submitted && $v.companion.gender.$error) }"
                            >
                              <option></option>
                              <option value="Masculino" v-if="type !== 5">Masculino</option>
                              <option value="Feminino" v-if="type !== 5">Feminino</option>
                              <option value="Man" v-if="type === 5">Masculino</option>
                              <option value="Woman" v-if="type === 5">Feminino</option>
                            </select>
                            <div
                              v-if="(submitted && $v.companion.gender.$error)"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.companion.gender.required">Campo obrigatório.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div :class="{'col-sm-2': (type === 4), 'col-sm-3': (type !== 4)}">
                          <div class="form-group">
                            <label for="nationality">Nacionalidade*</label>
                            <input
                              id="nationality"
                              v-model="companion.nationality"
                              name="nationality"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.nationality.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.nationality.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="birth_date">Data Nascimento*</label>
                            <date-picker
                              v-model="companion.birth_date"
                              :first-day-of-week="1"
                              lang="pt-br"
                              format="DD/MM/YYYY"
                               :class="{
                                'is-invalid': submitted && $v.companion.birth_date.$error
                              }"
                            >
                            </date-picker>
                            <!-- <input
                              id="birth_date"
                              v-model="companion.birth_date"
                              name="birth_date"
                              v-mask="'##/##/####'"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.birth_date.$error
                              }"
                            /> -->
                            <div
                              v-if="submitted && !$v.companion.birth_date.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div :class="{'col-sm-2': (type === 4), 'col-sm-3': (type !== 4)}" v-if="type !== 5">
                          <div class="form-group">
                            <label for="identification_doc">Nº Identidade*</label>
                            <input
                              id="identification_doc"
                              v-model="companion.identification_doc"
                              name="identification_doc"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.identification_doc.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.identification_doc.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="doc_emission">Data Emissão*</label>
                            <date-picker
                              v-model="companion.doc_emission"
                              :first-day-of-week="1"
                              lang="pt-br"
                              format="DD/MM/YYYY"
                              :class="{
                                'is-invalid': submitted && $v.companion.doc_emission.$error
                              }"
                            >
                            </date-picker>
                            <!-- <input
                              id="doc_emission"
                              v-model="companion.doc_emission"
                              name="doc_emission"
                              v-mask="'##/##/####'"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.doc_emission.$error
                              }"
                            /> -->
                            <div
                              v-if="submitted && !$v.companion.doc_emission.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="doc_validity">Data Validade*</label>
                            <date-picker
                              v-model="companion.doc_validity"
                              :first-day-of-week="1"
                              lang="pt-br"
                              format="DD/MM/YYYY"
                              :class="{
                                'is-invalid': submitted && $v.companion.doc_validity.$error
                              }"
                            >
                            </date-picker>
                            <!-- <input
                              id="doc_validity"
                              v-model="companion.doc_validity"
                              name="doc_validity"
                              v-mask="'##/##/####'"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.doc_validity.$error
                              }"
                            /> -->
                            <div
                              v-if="submitted && !$v.companion.doc_validity.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div :class="{'col-sm-3': (type === 5), 'col-sm-2': (type !== 5)}"
                          v-if="(type === 5) || (type === 4)">
                          <div class="form-group">
                            <label for="passport_doc">Nº Passaporte*</label>
                            <input
                              id="passport_doc"
                              v-model="companion.passport_doc"
                              name="passport_doc"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.passport_doc.$error
                              }"
                            />
                             <div
                              v-if="submitted && !$v.companion.passport_doc.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="doc_country">País de emissão do documento*</label>
                            <input
                              id="doc_country"
                              v-model="companion.doc_country"
                              name="doc_country"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.doc_country.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.doc_country.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="birth_city">Cidade de Nascimento*</label>
                            <input
                              id="birth_city"
                              v-model="companion.birth_city"
                              name="birth_city"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.birth_city.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.birth_city.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="zip_code">CEP*</label>
                            <input
                              id="zip_code"
                              v-model="companion.zip_code"
                              name="zip_code"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.zip_code.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.zip_code.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="address">Endereço*</label>
                            <input
                              id="address"
                              v-model="companion.address"
                              name="address"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.address.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.address.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label for="city">Cidade*</label>
                            <input
                              id="city"
                              v-model="companion.city"
                              name="city"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.city.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.city.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="state" v-if="type !== 5">UF*</label>
                            <label for="state" v-if="type === 5">Estado*</label>
                            <select
                              v-if="type !== 5"
                              class="form-control"
                              v-model="companion.state"
                              :class="{ 'is-invalid': submitted && $v.companion.state.$error }"
                            >
                              <option></option>
                              <option
                                v-for="(opt,idx) in states"
                                :key="idx"
                                :value="opt.value"
                              >
                                {{ opt.value || 'no label' }}
                              </option>
                            </select>
                            <input
                              v-if="type === 5"
                              id="state"
                              v-model="companion.state"
                              name="state"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.state.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.state.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="country">País*</label>
                            <input
                              id="country"
                              v-model="companion.country"
                              name="country"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.country.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.country.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="address_complement">Complemento do endereço*</label>
                            <input
                              id="address_complement"
                              v-model="companion.address_complement"
                              name="address_complement"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.address_complement.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.address_complement.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label for="food_restriction">Restrição Alimentar ?</label>
                            <input
                              id="food_restriction"
                              type="text"
                              v-model="companion.food_restriction"
                              name="food_restriction"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="polo_tshirt">Tamanho de camisa polo</label>
                            <input
                              id="polo_tshirt"
                              v-model="companion.polo_tshirt"
                              name="polo_tshirt"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.polo_tshirt.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.polo_tshirt.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="form-group">
                            <label for="shoe_size">Tamanho de calçado</label>
                            <input
                              id="shoe_size"
                              v-model="companion.shoe_size"
                              name="shoe_size"
                              type="text"
                              class="form-control"
                              :class="{
                                'is-invalid': submitted && $v.companion.shoe_size.$error
                              }"
                            />
                            <div
                              v-if="submitted && !$v.companion.shoe_size.required"
                              class="invalid-feedback"
                            >
                              O campo é obrigatório.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="obs">Observação</label>
                            <textarea
                              id="obs"
                              rows="3"
                              v-model="companion.obs"
                              name="obs"
                              type="text"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.aereo-2
                      class="text-dark"
                      href="javascript: void(0);"
                    >Transporte Aéreo</a>
                    <b-badge
                      v-show="(companion.air_transport > 0) && $v.transportA.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="aereo-2" accordion="companion-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="form-group row mb-9">
                      <label for="session_register" class="col-form-label col-lg-3">Precisa de transporte aéreo ?</label>
                      <div class="col-lg-2">
                        <select
                          class="custom-select"
                          name="air_transport"
                          id="air_transport"
                          v-model="companion.air_transport"
                          :class="{
                            'is-invalid': submitted && $v.companion.air_transport.$error,
                          }">
                          <option value="1">SIM</option>
                          <option value="0">NÃO</option>
                        </select>
                        <div
                          v-if="submitted && !$v.companion.air_transport.required"
                          class="invalid-feedback"
                        >
                          O campo é obrigatório.
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="companion.air_transport > 0">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="boarding_date">Data de chegada ao Rio de Janeiro</label>
                          <date-picker
                            v-model="transportA.boarding_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                            :class="{
                              'is-invalid': submitted && $v.transportA.boarding_date.$error,
                            }"
                          >
                          </date-picker>
                          <div
                            v-if="submitted && !$v.transportA.boarding_date.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="form-group">
                          <label for="departure_airport">Aeroporto Embarque</label>
                          <input
                            id="departure_airport"
                            v-model="transportA.departure_airport"
                            name="departure_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transportA.departure_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transportA.departure_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="return_date">Data de retorno para sua ciadde de origem</label>
                          <date-picker
                            v-model="transportA.return_date"
                            :first-day-of-week="1"
                            lang="pt-br"
                            format="DD/MM/YYYY"
                            :class="{
                              'is-invalid': submitted && $v.transportA.return_date.$error,
                            }"
                          >
                          </date-picker>
                          <div
                            v-if="submitted && !$v.transportA.return_date.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="form-group">
                          <label for="return_airport">Aeroporto Retorno</label>
                          <input
                            id="return_airport"
                            v-model="transportA.return_airport"
                            name="return_airport"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.transportA.return_airport.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.transportA.return_airport.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" role="tab">
                  <h6 class="m-0">
                    <a
                      v-b-toggle.contato-3
                      class="text-dark"
                      href="javascript: void(0);"
                    >Contato de Emergência</a>
                    <b-badge
                      v-show="$v.contactA.$error"
                      variant="danger"
                      class="float-right"
                    >
                      Atenção! Existem campos a serem preenchidos.
                    </b-badge>
                  </h6>
                </b-card-header>
                <b-collapse id="contato-3" accordion="companion-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label for="name">Grau de parentesco</label>
                          <input
                            id="name"
                            v-model="contactA.type"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contactA.type.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contactA.type.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name">Nome completo</label>
                          <input
                            id="name"
                            v-model="contactA.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contactA.name.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contactA.name.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="form-group">
                          <label for="phone">Telefone</label>
                          <input
                            id="phone"
                            v-model="contactA.phone"
                            name="phone"
                            type="phone"
                            v-mask="'X##(##) #####-####'"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.contactA.phone.$error,
                            }"
                          />
                          <div
                            v-if="submitted && !$v.contactA.phone.required"
                            class="invalid-feedback"
                          >
                            O campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveCompanion()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'
import { states } from './statesData'
import DatePicker from 'vue2-datepicker'
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { cpf } from 'cpf-cnpj-validator'

export default {
  components: {
    Loading,
    DatePicker
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    },
    type: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Cadastrar Acompanhante',
      loading: true,
      errorCPF: false,
      selected: '',
      fullPage: true,
      submitted: false,
      states: states,
      companion: {
        type: 0,
        group_id: 1,
        air_transport: 0
      },
      transportA: {
        boarding_date: '',
        departure_airport: '',
        return_date: '',
        return_airport: ''
      },
      contactA: {
        type: '',
        name: '',
        phone: '',
        obs: ''
      },
      modal: false
    }
  },
  validations () {
    let transport = {}
    let fNormal = {
      event_companion: { required },
      travel_companion: { required },
      name: { required },
      full_name: { required },
      last_name: { required },
      badge_name: { required },
      email: { required, email },
      phone: { required },
      gender: { required },
      nationality: { required },
      birth_date: { required },
      doc_emission: { required },
      doc_validity: { required },
      doc_country: { required },
      birth_city: { required },
      zip_code: { required },
      address: { required },
      city: { required },
      state: { required },
      country: { required },
      address_complement: { required },
      polo_tshirt: { required },
      shoe_size: { required },
      air_transport: { required }
    }
    if (this.type && this.type !== 5) {
      fNormal = {
        ...fNormal,
        cpf: { required },
        identification_doc: { required }
      }
    }
    if ((this.type && this.type === 5) || (this.type && this.type === 4)) {
      fNormal = {
        ...fNormal,
        passport_doc: { required }
      }
    }
    if (this.companion.air_transport && this.displayAereo) {
      transport = {
        ...transport,
        departure_airport: { required },
        return_airport: { required },
        return_date: { required },
        boarding_date: { required }
      }
    }
    return {
      companion: fNormal,
      contactA: {
        type: { required },
        name: { required },
        phone: { required }
      },
      transportA: transport }
  },
  methods: {
    ...mapActions('participant', {
      postData: 'save'
    }),
    validCPF () {
      const cpfValid = cpf.isValid(this.companion.cpf)
      if (!cpfValid) {
        this.errorCPF = true
        return
      }
      this.errorCPF = false
    },
    loadItem (id) {
      this.loading = false
      if (!id) {
        this.close()
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveCompanion () {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      if (typeof (this.selected) === 'object') {
        this.companion.state = this.selected.value
      }
      this.companion.participant_id = this.idParticipant
      this.companion.type_ref = this.type
      this.companion.transport = this.transportA
      this.companion.contact = this.contactA
      await this.postData(this.companion).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.companion = {}
      this.transportA = {}
      this.contactA = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    },
    'companion.state' (value) {
      this.selected = this.states.filter(o => o.value === value)[0]
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.modal-header {
  background: #000 !important;
  color: #fff;
}
</style>
