<template>
  <div>
    <div class="row">
      <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
      </loading>
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th width="5%">#</th>
                <th width="15%">Data</th>
                <th width="30%">Tipo</th>
                <th witdh="45%">Observação</th>
                <th width="10%">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attachment in attachments" :key="attachment.id">
                  <td><input type="checkbox" v-model="attachment.checked" v-if="attachment.user_operator_id"></td>
                  <td class="font-size-12">{{ $moment(attachment.created_at).format('DD/MM/YYYY HH:mm') }}</td>
                  <td>
                    {{ types[attachment.type] }}
                    <span class="badge badge-pill font-size-12" :class="{
                      'badge-soft-primary': !attachment.user_operator_id,
                      'badge-soft-success': attachment.user_operator_id
                    }">{{ !attachment.user_operator_id ? 'RECEBIDO' : 'ANEXADO' }}</span>
                  </td>
                  <td>
                    <i v-if="isUserAdmin || isUserMaster" class="bx bx-edit-alt font-size-15 link" @click="openObs(attachment)"></i>
                    <textarea class="form-control" v-if="editObs" v-model="attachment.obs"></textarea>
                     {{ attachment.obs }}
                  </td>
                  <td>
                    <a :href="attachment.document" target="_blank" class="link">
                      <button type="button" class="btn btn-outline-secondary btn-sm">Ver</button>
                    </a>
                    <button v-if="attachment.user_operator_id" type="button" @click="deleteAttachment(attachment)" class="link ml-1 btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <b-button v-if="hasAttachmentByOperator && (isUserAdmin || isUserMaster)" variant="outline-success" size="sm" @click="sendAttachment()">Enviar via E-mail</b-button>
      <b-button variant="outline-primary" class="dotted ml-2" size="sm" @click="openForm()" v-if="isUserAdmin || isUserMaster">Adicionar anexo</b-button>
    </div>
    <form-attachment :idParticipant="idParticipant" v-model="modalForm" ref="uploadAttachment" @createdAttachment="updateList"></form-attachment>
    <edit-attachment v-model="modalEdit" ref="editAttachment" @updatedAttachment="updateList"></edit-attachment>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'
import FormAttachment from './FormUpload'
import EditAttachment from './EditAttachment'

export default {
  components: {
    Loading,
    FormAttachment,
    EditAttachment
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      attachments: [],
      submitted: false,
      editObs: false,
      showFormAttachment: false,
      statusClass: ['dark', 'primary', 'warning', 'success', 'danger'],
      types: [
        '',
        'Documento de Identidade',
        'Passaporte',
        'Cartão de Vacina Covid-19',
        'Comp. Vacina de Febre Amarela',
        'Aéreo ida',
        'Aéreo volta',
        'Aéreo ida e volta',
        'Transfer',
        'Voucher MSC'
      ],
      modalForm: false,
      modalEdit: false
    }
  },
  beforeMount () {
    this.GetAttachments(this.idParticipant).then((res) => {
      this.attachments = res.data
      this.loading = false
    }).catch(error => {
      console.log(error)
      this.loading = false
    })
  },
  methods: {
    ...mapActions('participant', {
      GetAttachments: 'getAllAttachments',
      destroyAttachment: 'delAttachment',
      sendEmailAttachment: 'sendEmailAttach'
    }),
    openForm () {
      this.$refs.uploadAttachment.open(this.participantId)
      this.modalForm = true
    },
    openObs (attachment) {
      this.$refs.editAttachment.open(attachment)
      this.modalEdit = true
    },
    deleteAttachment (attachment) {
      Swal.fire({
        title: 'Você tem certeza?',
        html: `Será excluído o anexo: <b>${this.types[attachment.type]}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#eb0101',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.destroyAttachment(attachment.id).then((res) => {
              successMessage('Sucesso: Anexo removido.', 2500)
            }).then(() => {
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    sendAttachment () {
      const attachments = this.attachments.filter(o => o.checked === true)
      let list = ''
      attachments.map((a, i) => {
        list += `<li><b>${this.types[a.type]}</b></li>`
      })
      Swal.fire({
        title: 'Você tem certeza?',
        html: `Será enviado via e-mail os itens abaixo: <br /> ${list}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#388741',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, ENVIAR E-MAIL!'
      }).then(result => {
        if (result.value) {
          try {
            this.sendEmailAttachment({ id: this.idParticipant, attachments: attachments }).then((res) => {
              successMessage('Sucesso: E-mail enviado.', 2500)
            }).then(() => {
              this.updateList()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            console.log(err)
          }
        }
      })
    },
    updateList () {
      this.GetAttachments(this.idParticipant).then((res) => {
        this.attachments = res.data
        this.loading = false
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    hasAttachmentByOperator () {
      return this.attachments.filter(o => o.user_operator_id > 0).length
    },
    isUserAdmin () {
      return this.$store.getters['auth/isAdmin']
    },
    isUserMaster () {
      return this.$store.getters['auth/isMaster']
    }
  }
}
</script>
<style scoped>
.dotted { border: 1px dotted #556ee6 !important}
</style>
