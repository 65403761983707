import { render, staticRenderFns } from "./participant-statistic.vue?vue&type=template&id=45f6fb34&"
import script from "./participant-statistic.vue?vue&type=script&lang=js&"
export * from "./participant-statistic.vue?vue&type=script&lang=js&"
import style0 from "./participant-statistic.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports