<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="md"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="type">Tipo</label>
          <select
            class="custom-select"
            name="type"
            id="type"
            v-model="form.type"
            :class="{
              'is-invalid': submitted && $v.form.type.$error,
            }"
          >
            <option v-for="(type, i) in types" :key="i" :value="i">{{ type }}</option>
          </select>
          <div
            v-if="submitted && !$v.form.type.required"
            class="invalid-feedback"
          >
          O campo é obrigatório
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <label for="document">Arquivo</label>
          <input
            ref="attachment_participant"
            id="document"
            name="document"
            type="file"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label for="obs">Observação</label>
          <textarea
            id="obs"
            v-model="form.obs"
            name="obs"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="saveAttachment()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  props: {
    idParticipant: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Adicionar Anexo',
      loading: true,
      fullPage: true,
      submitted: false,
      form: {
        type: '',
        document: '',
        obs: '',
        participant_id: ''
      },
      modal: false,
      types: [
        '',
        'Documento de Identidade',
        'Passaporte',
        'Cartão de Vacinação Covid-19',
        'Comprovante Vacinação de Febre Amarela',
        'Aéreo ida',
        'Aéreo volta',
        'Aéreo ida e volta',
        'Transfer',
        'Voucher MSC'
      ]
    }
  },
  validations: {
    form: {
      type: { required }
    }
  },
  methods: {
    ...mapActions('participant', {
      postDataAttachment: 'uploadAttachment'
    }),
    open () {
      this.modal = true
      this.loading = false
    },
    setDocument (e) {
      this.form.document = 1
    },
    async saveAttachment () {
      console.log(this.$v)
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      if (!this.$refs.attachment_participant.files[0]) {
        errorMessage('Alerta: Você precisa anexar um arquivo')
        return
      }

      this.loading = true
      const formData = new FormData()
      formData.append('participant_id', this.idParticipant)
      formData.append('type', this.form.type)
      formData.append('document', this.$refs.attachment_participant.files[0])
      formData.append('obs', this.form.obs ?? '')
      await this.postDataAttachment(formData).then(res => {
        let message = 'Sucesso: Anexo armazenado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('createdAttachment')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.submitted = false
      this.modal = false
      this.form = {}
      this.$v.$reset()
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>

</style>
